.collection-item {
  position: relative;

  &:hover &__text {
    visibility: visible;
    transition: opacity 0.62s, ease 1s;
    opacity: 1;
  }

  &:hover &__addtolist {
    visibility: visible;
    opacity: 1;
  }

  &:hover &__movie-image {
    transition: opacity 0.62s, ease 1s;
    opacity: 0.5;
  }

  &__movie-image {
    width: 300px;
    z-index: 1;

    @media screen and (max-width: 800px) {
      width: 230px;
    }
  }

  &__text {
    position: absolute;
    bottom: 24%;
    left: 2%;
    right: 1%;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.62s, ease 1s;
    font-size: 10px;
    text-shadow: 3px 3px 4px black;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__title {
    color: white;
    font-size: 0.9rem;

    @media screen and (max-width: 800px) {
      font-size: 0.6rem;
    }
  }

  &__overview {
    color: white;

    @media screen and (max-width: 800px) {
      font-size: 0.6rem;
    }
  }

  &__addtolist {
    transition: opacity 0.25s, ease 1s;
    position: absolute;
    bottom: 5%;
    padding: 0 12px;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
