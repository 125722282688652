.PlayerWrapper {
  z-index: 99;
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: hidden;
}

.PreviousLink {
  z-index: 100;
  top: 20px;
  right: 20px;
  position: fixed;
  background-color: black;
  color: white;
  font-size: 24px;
  border-radius: 99px;
}
