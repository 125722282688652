.addtolist {
  &__container {
    display: flex;
    align-items: center;
  }

  &--icon {
    display: inline;
    margin-right: 6px;
  }
}
